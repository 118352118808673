import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';
import Sidebar2024 from '../ScienceClub/Sidebar2024';
import Scienceclubsidebar2023 from '../ScienceClub/Scienceclubsidebar2023';
import ScienceClubSideBar from '../ScienceClubSideBar';

const ScienceAssociation6to82024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/ScienceClub/ScienceAssociation6t08/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/ScienceClub/ScienceAssociation6t08/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/ScienceClub/ScienceAssociation6t08/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/ScienceClub/ScienceAssociation6t08/4.webp`;
    
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1,
        },
       
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                "Moon: Past, Present, and Future" 

                                </Typography>
                                <br />
                                {/* <Typography variant='h7' align='justify' >
                                Class 3: Vegan power,
                                Class 4: Plant Power Saves the World,
                                Class 5: Significance of spices in Indian Cuisine  
                                </Typography> */}
                                <Typography
                                    fontSize="x-small"
                                > <br />
                                    Classes: 6 - 8    Date: 15-11-2024

                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Science Association organised an interesting competition comprising 3 rounds for classes 6, 7 and 8. The preliminary round, elocution on the topic "Moon: Past, Present, and Future" was held on Friday, 6 September, 2024. 
                                    <br/>
                                    The activity showcased the creativity, knowledge and presentation skills of the students. Selected participants from preliminary round were grouped house wise. The second and third rounds were held on 15 November, 2024, where students worked in teams to identify the Science laboratory apparatus using Pictionary.
                                    <br />
                                    They also spoke about the apparatus. In the final round, the teams were given a box containing science related materials and they created a story in the form of role play and skit with the aid of the given props.
                                    <br />
                                    The competition challenged the participants to showcase their creativity, inventiveness and ability to use imagination to create something unique that captured the audience's attention. It helped them learn important values like cooperation and teamwork as they introduced new ideas and exercised their creative thinking.
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “BRINGING SCIENCE TO LIFE"
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar />

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default ScienceAssociation6to82024;